import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    isLoggin: boolean = false
    userProfile: any = {}
    permissions: any = []
    stageOption: any = []
    constructor(
        public router: Router,
        public api: ApiService,
    ) { }
    allowPermission(perm: any) {
        if (this.userProfile.role_id == '-1') return true
        return this.permissions.includes(perm)
    }
    checkAuth() {
        let profile = localStorage.getItem(environment.userKey)
        let token = localStorage.getItem(environment.accessToken)
        let permission: any = localStorage.getItem(environment.permissionKey)
        if (profile && token) {
            this.userProfile = JSON.parse(atob(atob(profile)))
            this.permissions = permission ? JSON.parse(atob(atob(permission))) : []
            this.isLoggin = true
            let stage = localStorage.getItem('stage')
            let selectedStage = localStorage.getItem('selected-stage')
            this.userProfile.selectedStage = selectedStage ? JSON.parse(selectedStage) : null
            if (!stage) this.router.navigate(['/switch'])
        }
    }

    setAuth(data: any) {
        if (data.token && data.profile) {
            localStorage.setItem(environment.userKey, btoa(btoa(JSON.stringify(data.profile))))
            localStorage.setItem(environment.permissionKey, btoa(btoa(JSON.stringify(data.permissions))))
            localStorage.setItem(environment.accessToken, data.token)
            this.isLoggin = true
            this.userProfile = data.profile
            this.permissions = data.permissions
            this.api.syncLocalStorage()
            if (data.bypass_stage) {
                localStorage.setItem('stage', '1')
                this.router.navigate(['/dashboard'])
            } else {
                this.stageOption = data.stages ? data.stages : []
                this.router.navigate(['/switch'])
            }
        }
    }

    logout() {
        this.isLoggin = false
        this.userProfile = {}
        this.permissions = []
        localStorage.clear()
        this.api.syncLocalStorage()
        this.router.navigate(['/auth/login'])
        // setTimeout(() => {
        //     window.location.reload()
        // }, 50)
    }

    ngOnDestroy() {

    }
}
