
export const environment = {
  appName: "E-Arsip Al Hamidiyah",
  // baseUrl: 'http://192.168.196.89:9000/',
  // baseUrl: 'http://localhost:9001/',
  baseUrl: 'https://arsip.alhamidiyah.ponpes.id/api/',
  userKey: 'EARSIPUKS',
  accessToken: 'EARSIPACT',
  permissionKey: 'EARSIPM',
  production: false,
  appVersion: '1.00',
  defaultMaxUpload: 10,//MB
};
