<div class="containter h-100 bg-white animated">
    <div class="d-flex flex-column flex-root h-100">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2">
                <div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

                    <div class="row w-100 justify-content-center">
                        <!--begin::Col-->
                        <div class="col-sm-12 col-md-3 cursor-pointer mb-5" *ngFor="let item of data"
                            (click)="stageSelected(item)">
                            <!--begin::Hot sales post-->
                            <div class="card">
                                <!--begin::Overlay-->
                                <div class="card-body">
                                    <a class="d-flex flex-column">
                                        <!--begin::Image-->
                                        <div class="symbol mb-5 m-auto text-center symbol-150px max-h-200px">
                                            <img [src]="companyProfile?.logo_company"
                                                onerror="this.src='assets/images/file/blank-image.svg'"
                                                class="image rounded">
                                        </div>
                                    </a>

                                    <div class="card-footer border-0 p-5 text-center rounded"
                                        style="background-color:  #17B1AD;">
                                        <h3 class="text-white mb-0">
                                            {{item.name}}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>