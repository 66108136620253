import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import axios from 'axios'
import { Router } from "@angular/router";
import { Util } from "./util";
@Injectable({ providedIn: 'root' })
export class ApiService {
    token: any = '';
    confApi: any = {};
    headers: any
    constructor(
        private router: Router,
        public util: Util
    ) {
        this.syncLocalStorage()
    }
    syncLocalStorage() {
        console.log("INIT LOCAL STORAGE")
        this.token = localStorage.getItem(environment.accessToken)
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: this.token
        }
    }
    logOut() {
        this.token = ''
        localStorage.clear()
        this.router.navigate(['/auth/login'])
    }
    get(path: any, param: any = {}) {
        let header = { headers: this.headers, params: param };
        return axios.get(`${environment.baseUrl}${path}`, header)
            .then((resp) => { return resp.data.data })
            .catch((err: any) => {
                this.coreException(err)
                let error = err.response ? err.response.data : {}
                throw error;
            });
    }
    post(path: any, body: any = {}, message: boolean = true) {
        let header = { headers: this.headers };
        return axios.post(`${environment.baseUrl}${path}`, body, header)
            .then((resp) => { return resp.data.data })
            .catch((err: any) => {
                if (message) this.coreException(err)
                let error = err.response ? err.response.data : {}
                throw error;
            });
    }
    patch(path: any, body: any = {}) {
        let header = { headers: this.headers };
        return axios.patch(`${environment.baseUrl}${path}`, body, header)
            .then((resp) => { return resp.data.data })
            .catch((err: any) => {
                this.coreException(err)
                let error = err.response ? err.response.data : {}
                throw error;
            });
    }
    delete(path: any, param: any = {}) {
        let header = { headers: this.headers, params: param };
        return axios.delete(`${environment.baseUrl}${path}`, header)
            .then((resp) => {
                // this.presentToast(`Delete successfull`)
                return resp.data.data
            })
            .catch((err: any) => {
                this.coreException(err)
                let error = err.response ? err.response.data : {}
                throw error;
            });
    }

    upload(file: any) {
        var formData = new FormData()
        formData.append("file", file)
        return axios.post(`${environment.baseUrl}upload/file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: this.token
            }
        })
            .then((res) => { return res.data.data })
            .catch((err) => {
                this.coreException(err)
                let error = err.response ? err.response.data : {}
                throw error;
            })
    }
    download(path: any, param: any = {}, fileName: any = '') {
        let header: any = { headers: this.headers, params: param, responseType: 'blob' }
        return axios.get(`${environment.baseUrl}${path}`, header)
            .then((resp: any) => {
                let fileExtension = resp.headers["content-type"].split("/")
                fileExtension = fileExtension[fileExtension.length - 1]
                fileName = `${fileName}.${fileExtension}`
                this.getFile(resp.data, fileName)
                return {
                    filename: fileName,
                    extension: fileExtension
                }
            })
            .catch((err: any) => {
                this.coreException(err)
                let error = err.response ? err.response.data : {}
                throw error;
            });
    }

    getFile(data: string | ArrayBuffer | ArrayBufferView | Blob, filename: string, mime?: string, bom?: string | Uint8Array) {
        var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
        var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200)
    }
    coreException(err: any) {
        if (!err.response) {
            this.util.swalAlert(`Please check internet connection`, '', 'error')
            return
        }
        if (err.response.status) {
            const status = err.response.status;
            let title = err.response.data.error_message;
            title = title.charAt(0).toUpperCase() + title.slice(1);
            this.util.swalAlert(title, '', 'warning')
            if (status == 401) this.logOut()
            else if (status === 450) console.log('redirec')
        }
    }
}