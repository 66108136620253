import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { ClipboardService } from 'ngx-clipboard'
import Swal from 'sweetalert2'
import { initConfig } from './iconConfig'

@Injectable({
    providedIn: 'root'
})
export class Util {
    currentPlugin: any = {}
    currentPresenceMethode: any = {}
    lockContent: boolean = false
    lockContentName: any = ''
    dinamicVariable: any = {}
    tempData: any = {}
    constructor(
        private _clipboardService: ClipboardService
    ) { }
    swalAlert(title: any, body: any = '', color: any) {
        Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        })
            .fire(title, body, color)
    }
    getIcon(ext: any = "") {
        return initConfig[ext] ? initConfig[ext] : "assets/images/ekstensi/file.png"
    }
    currentDate() {
        moment.locale('id')
        return moment().format('YYYY-MM-DD')
    }

    currentDateTime() {
        moment.locale('id')
        return moment().format('YYYY-MM-DD HH:mm:ss')
    }

    setDateTime(val: any) {
        moment.locale('id')
        return moment(val).format('YYYY-MM-DD HH:mm:ss')
    }

    setDate(val: any) {
        moment.locale('id')
        return moment(val).format('YYYY-MM-DD')
    }

    money(value: any) {
        if (!value) return 0
        var val = value.toString().replace(/\,/g, ".")
        var newValue = parseFloat(val).toFixed(0)
        newValue = newValue.toString().replace(/\,/g, '.')
        newValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return newValue
    }
    monthString(value: any) {
        let data = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"]
        return data[parseInt(value) - 1]
    }
    dateString(date: any, time = false, format = 1) {
        if (!date) return ''
        date = date ? date : moment().format('YYYY-MM-DD')
        let y = moment(date).format('YYYY')
        let month = moment(date).format('MM')
        let m = this.monthString(month)
        let d = moment(date).format('DD')
        let times = time ? moment(date).format('HH:mm') : ''

        let str = `${d} ${m} ${y} ${times}`
        if (format == 2) str = `${d}/${month}/${y} ${times}`
        return str
    }
    timeString(times: any, format = 1) {
        if (!times) return ''
        times = `2022-01-01 ${times}`
        let h: any = moment(times).format('HH')
        let m: any = moment(times).format('mm')
        let a = `${h} Jam ${m} Menit`
        h = parseInt(h)
        m = parseInt(m)
        if (format == 2) {
            a = h > 0 && m > 0 ? `${h} Jam ${m} Menit` : h < 1 && m > 0 ? `${m} Menit` : `${h} Jam ${m} Menit`
        }
        return a
    }
    numberOfDay(date: any = '') {
        moment().locale("id")
        date = date ? date : moment().utcOffset(7).format('YYYY-MM-DD')
        let day: any = {
            Mo: 'Senin',
            Tu: 'Selasa',
            We: 'Rabu',
            Th: 'Kamis',
            Fr: 'Jumat',
            Sa: 'Sabtu',
            Su: 'Minggu',
        }
        let check = moment(date).utcOffset(7).format('dd')
        return day[check]
    }

    parseDesimal(data: any) {
        let value = parseFloat(data)
        if (isNaN(value) || value == null || data == undefined) return 0
        else return value
    }

    dateToString(date: any) {
        if (!date) return ''
        let a = moment(date).format('MM')
        let b = moment(date).format('YYYY')
        let c = moment(date).format('DD')
        return `${c} ${this.monthString(a)} ${b}`
    }


}