import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit {
  data: any = []
  companyProfile: any = {}
  isLoading: boolean = false
  constructor(
    private api: ApiService,
    public router: Router
  ) { }
  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.api.get('switch/stage')
      .then((res) => {
        this.data = res.data
        this.companyProfile = res.company_profile
      })
      .catch((err) => {
        console.log(err)
      })
  }

  stageSelected(item: any) {
    if (this.isLoading) return
    this.isLoading = true
    let body: any = {
      stage_id: item.id
    }
    this.api.post(`switch/stage`, body)
      .then((res) => {
        localStorage.setItem(environment.accessToken, res.token)
        localStorage.setItem(`stage`,"1")
        localStorage.setItem(`selected-stage`,JSON.stringify(item))
        this.api.syncLocalStorage()
        setTimeout(() => {
          // this.router.navigateByUrl('dashboard')
          window.location.href=''
        }, 50)
      })
      .catch((err) => {
        this.api.util.swalAlert(`Gagal memilih jenjang`, '', 'warning')
        this.isLoading = false
      })
  }
}
