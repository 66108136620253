export const initConfig: any = {
    //FILE EXTENTION : "FILE PATH"
    "png": "assets/images/ekstensi/png.png",
    "jpg": "assets/images/ekstensi/jpg.png",
    "zip": "assets/images/ekstensi/zip.png",
    "rar": "assets/images/ekstensi/rar.png",
    "mp4": "assets/images/ekstensi/mp4.png",
    "mp3": "assets/images/ekstensi/mp3.png",
    "docx": "assets/images/ekstensi/docx.png",
    "ppt": "assets/images/ekstensi/ppt.png",
    "pdf": "assets/images/ekstensi/pdf.png",
    "xls": "assets/images/ekstensi/xls.png",
    "doc": "assets/images/ekstensi/doc.png",
    "pptx": "assets/images/ekstensi/pptx.png",
    "jpeg": "assets/images/ekstensi/jpeg.png",
    "svg": "assets/images/ekstensi/svg.png",
    "xlsx": "assets/images/ekstensi/xlsx.png",
    "avif": "assets/images/ekstensi/avif.png",
    "eps": "assets/images/ekstensi/eps.png",
    "gif": "assets/images/ekstensi/gif.png",
    "psd": "assets/images/ekstensi/psd.png",
    "rtf": "assets/images/ekstensi/rtf.png",
    "ppsx": "assets/images/ekstensi/ppsx.png",
    "pdp": "assets/images/ekstensi/pdp.png",
    "mov": "assets/images/ekstensi/film.png",
}

